import React from 'react'
import Footer from './Footer'
import { isLoggedIn } from '../utils/LoggedIn'
import Header2 from './Header2'
import Header from './Header'

const OurAccredition = () => {
    return (
        <div>
            {
                isLoggedIn("swtadmin") ? <Header2 /> : <Header />
            }
            <main id="main" className="ProductMain">
                <section>
                    <div className="FilterProductsArea">
                        <div className="AboutArea">
                            <div className="section-title">
                                <h1>Our Industry Accreditations: A Mark of Excellence</h1>
                            </div>
                            <p className="text-center">
                                At SWT CLUB, we take pride in our commitment to delivering
                                unparalleled travel and educational services. Our dedication to
                                quality, innovation, and customer satisfaction is reflected in the
                                prestigious accreditations and recognitions we've earned over the
                                years.
                            </p>
                        </div>
                    </div>
                </section>
                <div className="industryAccreditionArea">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/iata.jpeg')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    <strong>IATA (International Air Transport Association):</strong> As
                                    a recognized member of IATA, we adhere to the highest standards of
                                    professionalism in global travel operations.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/etoa.jpeg')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    <strong>ETOA (European Tourism Association):</strong> Our membership
                                    with ETOA underscores our expertise in providing world-class
                                    European travel experiences.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/minister.jpeg')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    <strong>Ministry of Tourism (India):</strong> Accredited by the
                                    Ministry of Tourism, we uphold excellence in promoting India's rich
                                    travel heritage.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/IATO.png')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    <strong>IATO (Indian Association of Tour Operators):</strong> Being
                                    a proud member of IATO validates our credibility and expertise as a
                                    leading travel operator in India.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/tia-1.png')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    TIA (Tourism Industry Association): Our affiliation with TIA
                                    highlights our active participation in shaping the future of the
                                    tourism industry.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/tiss-logo.png')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    <strong>TISS (Tata Institute of Social Sciences):</strong> Through
                                    our collaboration with TISS, we are contributing to educational
                                    excellence and skill development initiatives.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="rightContent">
                                <figure>
                                    <img src={require('../assests/images/OTOAI-logo.png')} alt="" width={100} height={100} />
                                </figure>
                                <p>
                                    <strong>
                                        OTOAI (Outbound Tour Operators Association of India):
                                    </strong>{" "}
                                    As a member of OTOAI, we bring unmatched expertise in delivering
                                    outbound travel solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="awardRecognitionNew">
                    <div className="section-title">
                        <h5>Awards and Recognition</h5>
                        <p>
                            Our efforts in redefining travel and tourism have earned us global
                            acclaim:
                        </p>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="awardRecognitionBox">
                                <img src={require('../assests/images/2021-nominees.png')} alt="" width={150} height={100} />
                                <aside>
                                    <h6>World Travel Award 2021</h6>
                                    <p>
                                        Recognized for our outstanding contribution to the travel
                                        industry. (Nominee)
                                    </p>
                                </aside>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="awardRecognitionBox">
                                <img src={require('../assests/images/2022-nominees-shield.png')} alt="" width={150} height={100} />

                                <aside>
                                    <h6>World Travel Award 2022</h6>
                                    <p>
                                        A testament to our continued excellence and innovation in global
                                        travel services. (Nominee)
                                    </p>
                                </aside>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="awardRecognitionBox">
                                <p className="Last">
                                    These accreditations and awards reflect our unwavering dedication to
                                    delivering exceptional experiences to our clients and maintaining
                                    the highest standards in the travel industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default OurAccredition